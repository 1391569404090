.ym-button-primary {    
  &.p-button-text {
    color: var(--primary-ymunity);

    &:active {
      background: rgba(#797EF5, 0.2);
    }

    &:hover {
      background: rgba(#797EF5, 0.05);
    }
  }

  &:not(.p-button-text) {
    background: var(--primary-ymunity);
    border-color: var(--primary-ymunity);

    &:hover {
      background: var(--primary-hover);
      border-color: var(--primary-hover);
    }

    &:active {
      background: var(--primary-dark);
      border-color: var(--primary-dark);
    }

    &:disabled {
      background: var(--primary-bright);
      border-color: var(--primary-bright);
    }
  }

  &:focus {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

// ym-button-primary OUTLINED DEFAULT
.p-button.ym-button-primary.p-button-outlined,
  .p-buttonset.ym-button-primary > .p-button.p-button-outlined,
  .p-splitbutton.ym-button-primary > .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--primary-ymunity);
    border: 1px solid;
  }

  // ym-button-primary OUTLINED HOVER
  .p-button.ym-button-primary.p-button-outlined:enabled:hover,
  .p-buttonset.ym-button-primary > .p-button.p-button-outlined:enabled:hover,
  .p-splitbutton.ym-button-primary > .p-button.p-button-outlined:enabled:hover {
    background-color: var(--primary-ymunity);
    color: var(--grayscale-surface-white);
    border: 1px solid;
  }

  // ym-button-primary OUTLINED ACTIVE
  .p-button.ym-button-primary.p-button-outlined:enabled:active,
  .p-buttonset.ym-button-primary > .p-button.p-button-outlined:enabled:active,
  .p-splitbutton.ym-button-primary > .p-button.p-button-outlined:enabled:active {
    background-color: var(--primary-ymunity) ;
    color: var(--system-info-light);
    border: 1px solid;
  }